Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.landingPageEndPoint="catalogues"
exports.btnExampleTitle = "CLICK ME";

exports.cookiesHeading = "Choose your cookies";
exports.cookiesSubText = "We offer a variety of cookies to enhance your browsing experience. Some of these cookies are crucial for our website's functionality and are always enabled. Others are here to tailor your experience and remember your preferences. Feel free to customize your cookie settings and update them whenever you like.";
exports.cookiesAcceptButton = "Accept All";
exports.cookiesRejectButton = "Reject All";
exports.manageSettingText = "Manage Settings";
exports.cookiesSettingHeading = "Cookies Settings";
exports.tabText1 = "Your Privacy";
exports.tabText2 = "Strictly Necessary";
exports.tabText3 = "Functional";
exports.tabText4 = "Targeting";
exports.tabText1Description = "When you explore our prop-tech startup website, we may gather and store data in your web browser through small files known as cookies.These cookies enable us to identify and recall your presence.The information collected can pertain to your online activities, preferences, the device you're using, or may be essential for the proper functioning of our website.AS-Tx utilizes this information to enhance your personalized experience on our platform.You have the option to control your cookie settings using the 'Manage settings' feature, although please note that some cookies are indispensable for the optimal operation of AS-Tx."
exports.tabText2Description = "These essential cookies are vital for the proper operation of the AS-Tx's website. They perform essential functions such as configuring your privacy preferences, facilitating the login process to your AS-Tx account, and enabling form submissions. Please note that these cookies cannot be deactivated. However, you do have the option to configure your browser settings to either block or receive alerts about these cookies. Keep in mind that doing so may result in certain functionalities of our website not working as intended."
exports.tabText3Description = "Our functional cookies play a pivotal role in enhancing your experience on our website. They enable us to measure and improve the site's performance, remember your preferences, optimize content, and provide valuable tools like videos and currency converters. Importantly, all data used from these cookies is aggregated and anonymous, safeguarding your privacy. Allowing these cookies ensures a personalized and optimized experience while aiding the continuous improvement of our website. However, if you choose not to allow them, we won't know when you visit, hindering our ability to monitor and enhance our platform's performance.";
exports.tabText4Description = "These cookies capture your interactions with our website, AS-Tx. They collect data on the specific pages you explore, the links you click, and the type of device you're using. This information enables us to tailor the AS-Tx website and the advertisements you encounter to align more closely with your personal interests. Furthermore, permitting these cookies may result in you encountering AS-Tx advertisements when you browse Facebook";
exports.tabText5Description = "In addition to enhancing your experience on AS-Tx, these cookies also track your activities on our platform.Our partners may utilize this data to present you with advertisements that they believe will pique your interest when you explore other websites.Furthermore, our partners may analyze the data recorded by these cookies to assess the effectiveness of their advertisements.";
exports.saveText = "Save";
// Customizable Area End
