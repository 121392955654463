export const logo = require("../assets/logo.png");
export const location = require("../assets/location.png");
export const bedroom = require("../assets/bedroom.png");
export const bathroom = require("../assets/bathroom.png");
export const ownership = require("../assets/ownership.png");
export const parking = require("../assets/parking.png");
export const reception = require("../assets/reception.png");
export const Conservatory = require("../assets/Conservatory.png");
export const basement = require("../assets/basement.png");
export const Balcony = require("../assets/Balcony.png");
export const Pool = require("../assets/Pool.png");
export const garden = require("../assets/garden.png");
export const Exercise = require("../assets/Exercise.png");
export const Media = require("../assets/Media.png");
export const officehome = require("../assets/officehome.png");
export const wine = require("../assets/wine.png");
export const solar = require("../assets/solar.png");
export const image = require("../assets/image.png");
export const imgicon = require("../assets/imgicon.png");
export const delet = require("../assets/delete.png");
export const circle = require("../assets/circle.png");
export const cloud = require("../assets/cloud.png");
export const radioCheck = require("../assets/radioCheck.png");
export const radiouncheck = require("../assets/radiouncheck.png");
export const arrow = require("../assets/arrow.png");
export const nextarrow = require("../assets/nextarrow.png");
export const locationImage = require("../assets/locationImage.png");
export const share = require("../assets/share.png");
export const phone = require("../assets/phone.png");
export const company = require("../assets/company.png");
export const epc = require("../assets/EPC.png");
export const floor = require("../assets/floor.png");
export const cross = require("../assets/Icon.png");
export const thumb = require("../assets/thumb.png");
export const play = require("../assets/play.png");
export const tick = require("../assets/tick.png");