export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const backArrow = require("../assets/image_.png");
export const location = require("../assets/location.png");
export const locationCarousel = require("../assets/locationcarousel.png");
export const share = require("../assets/share.png");
export const play = require("../assets/play.png");
export const plus = require("../assets/plus.png");
export const video4 = require("../assets/videonew.mp4");
export const video1 = require("../assets/videotwo.mp4");

