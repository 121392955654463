Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableuserprofiles2";
exports.labelBodyText = "customisableuserprofiles2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.userProfileHeading= "Your Profile";
exports.userProfileSubHeading ="Enter your all Information";
exports.sellerProfileContact= "Contact Number";
exports.sellerProfileAddress ="Address";
exports.sellerProfileSellingTime= "Preferred Selling timeframe";
exports.sellerProfileMovingTime ="Preferred Moving timeframe";
exports.sellerProfileMortgage ="Any existing Mortgage or Liens on the property";

exports.buyerProfileContact= "Contact Number";
exports.buyerProfileLocation ="Preferred Location";
exports.buyerProfilePreference= "Property Preference";
exports.buyerProfileBudget ="Budget (£)";
exports.buyerProfileFinancingStatus ="Financing Status";
exports.buyerProfileBedrooms= "Bedrooms";
exports.buyerProfileBathrooms = "Bathrooms";
exports.buyerProfileAdditionalFeatures= "Additional Features";
exports.buyerProfileBuying = "Buying For";
exports.buyerProfileMortgage= "Mortgage Pre Approval Status";
exports.buyerProfileMovingTime ="Preferred Moving Timeframe";
exports.contactErrorMessage = "Please enter the correct Number";
exports.defautlCoutryCode = "+44";

exports.agentAgencyName = "*Agency Name";
exports.agentTradingName = "Trading Name";
exports.agentDirectorName = "*Name of Director";
exports.agentCompanyRegistration = "*Company Registration Number";
exports.agentRegOfficeAddress = "*Registered/Principal Office address";
exports.agentBranchAddress = "Branch Addresses";
exports.agentContactName = "*Contact Name";
exports.agentJobTitle = "*Job Title";
exports.agentContactNumber = "*Contact Number";
exports.agentDOI = "Date of Incorporation";
exports.agentDateCommenced = "Date Commenced Trading";
exports.agentWebsiteAddress = "Website Address";
exports.agentYearOfExperience = "*Years of Experience";
exports.agentLocationStock = "*Location of your property stock";
exports.agentRedressMember = "*Which redress scheme are you a member of?";
exports.agentRegNumber = "Registration Number";
exports.agentICONote = "*Please provide details of your Information Commissioners Office(ICO) registration.";
exports.agentICONumber = "*ICO registration number";
exports.agentICOExpiryDate = "*ICO registration expiry date";
exports.agentStockCity1 = "England";
exports.agentStockCity2= "Wales";
exports.agentStockCity3 = "Scotland";
exports.agentRedress1 = "The Property Ombudsman";
exports.agentRedress2 = "The Property Redress Scheme";
exports.agentProfessionalInsurance = "*Do you have a Professional Insurance Indemnity Policy?";
exports.agentInsuranceValue1 = "Yes";
exports.agentInsuranceValue2 = "No";
exports.agentHMRCNote = "*Are you registered with HMRC’s Anti Money Laundering Register?";
exports.agentHMRCRegNo = "Please provide your HMRC Supervised Business Registration Number";
exports.agentHMRRenewalDate = "Please provide your HMRC Supervised Business Renewal date";
exports.datePlaceholder = "DD/MM/YYYY";
exports.uploadText = "Upload Document";
exports.noteText1 = "Are you advertising properties in the Channel Isles, Northern Ireland or the Isle of Man";
exports.noteText2 = "I understand the personal information I have provided to AS-Tx.io may be";
exports.readMoreText = "Read more....";
exports.noteText3 = "shared with fraud prevention agencies who will use it to prevent fraud and money-laundering and to verify my identity. If fraud is detected, I understand I could be refused certain services, finance, or employment. For further details of how my information will be used by AS-Tx.io and these fraud prevention agencies, and my data protection rights, can be found by visiting https://www.cifas.org.uk/fpn. I confirm that the information given on this form is correct and gives a true representation of the business. The submission of this form does not constitute an application to join AS-Tx.io nor does it provide any guarantee that Rightmove membership will be created’";
exports.lessText = "Less";
exports.dateInvaildText = "Please enter the correct date";
exports.disclaimerInvalidText = "Please accept the disclaimer to proceed";

exports.solicitorContactNumber = "*Contact Number";
exports.solicitorFirmName = "*Firm Name";
exports.solicitorOfficeAddress = "*Office Address";
exports.solicitorYearsOfExp = "*Years of conveyancing experience";
exports.solicitorNumberOfSolicitors = "Number of Solicitors";
exports.solicitorLicenseNumber = "*License Number";
exports.solicitorSpecificExpertise = "*Specific expertise";
exports.solicitorInsurance = "*Insurance";
exports.solicitorIndemnityProvider = "Professional Indemnity provider";
exports.solicitorPolicyNumber = "Policy Number";
exports.solicitorAuthorities = "*Regulatory authorities";
exports.solicitorAuthoritiesValue1 = "Solicitors Regulation Authority (SRA)";
exports.solicitorAuthoritiesValue2 = "Council of Licensed Conveyancers (CLC)";
exports.solicitorAuthoritiesIdNo = "ID Number";
exports.solicitorAuthoritiesLicenceNo = "Licence Number";
exports.solicitorScheme = "Scheme";
exports.solicitorSchemeValue1 = "Conveyancing Quality Scheme (CQS)";
exports.solicitorSchemeValue2 = "Property Redress Scheme";
exports.solicitorSchemeName = "Name";
exports.solicitorSchemeMembershipNo = "Membership Number";
exports.solicitorSchemeValue3 = "Law Society (Lexcel)";
exports.licenseNoPlaceholder = "9876543213";
exports.policyNoPlaceholder = "9876543212";
exports.invalidText = "Please enter correct data";


exports.skipText ="Skip";
exports.saveText ="Save";

exports.apiGetCountryCodeContentType = "application/json";
exports.apiGetCountryCodeEndPoint = "account/accounts/country_code_and_flag";
exports.apiGetCountryMethod = "GET";

exports.putSellerProfileAPiMethod = "PUT";
exports.putSellerProfileAPiEndPoint = "profiles/update_my_profile";
exports.putSellerProfileSucessfulMessage = "Submitted Successfully";

exports.putBuyerProfileAPiMethod = "PUT";
exports.putBuyerProfileAPiEndPoint = "profiles/update_my_profile";
exports.putBuyerProfileSucessfulMessage = "Submitted Successfully";

exports.putAgentProfileAPiMethod = "PUT";
exports.putAgentProfileAPiEndPoint = "profiles/update_my_profile";
exports.putAgentProfileSucessfulMessage = "Submitted Successfully";

exports.putSolicitorProfileAPiMethod = "PUT";
exports.putSolicitorProfileAPiEndPoint = "profiles/update_my_profile";
exports.putSolicitorProfileSucessfulMessage = "Submitted Successfully";

exports.errorMessageForAll = "Fill in all your details";
// Customizable Area End