Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.emailIsRequired = "Email is required";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.firstNameIsRequired = "First Name is required";
exports.lastNameIsRequired = "Last Name is required";
exports.firmNameIsRequired = "Firm Name is required";
// Customizable Area End
