Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform3";
exports.labelBodyText = "customform3 Body";
exports.apiGetCountryCodeContentType = "application/json";
exports.apiGetCountryCodeEndPoint = "account/accounts/country_code_and_flag";
exports.apiGetCountryMethod = "GET";


exports.apiCreateMemorandumOfSaleContentType = "application/json";
exports.apiCreateMemorandumOfSaleEndPoint = "bx_block_custom_forms/memorandum_of_sales";
exports.apiCreateMemorandumOfSaleMethod = "POST";

exports.apiGetMemorandumOfSaleContentType = "application/json";
exports.apiGetMemorandumOfSaleEndPoint = "bx_block_custom_forms/memorandum_of_sales";
exports.apiGetMemorandumOfSaleMethod = "GET";

exports.apiUpdateMemorandumOfSaleContentType = "application/json";
exports.apiUpdateMemorandumOfSaleEndPoint = "bx_block_custom_forms/memorandum_of_sales";
exports.apiUpdateMemorandumOfSaleMethod = "PUT";

exports.apiAcceptDeclineMemorandumOfSaleContentType = "application/json";
exports.apiAcceptDeclineMemorandumOfSaleEndPoint = "bx_block_custom_forms/memorandum_of_sales/memorandum_accept_decline";
exports.apiAcceptDeclineMemorandumOfSaleMethod = "PUT";
exports.apartmentPrice = "£402,999";
exports.apartmentText = "Beautiful apartment";
exports.apartmentAddress = "Shree Garden 1132, UK, 756856";
exports.offerPriceLable = "*Offer Price (£)";
exports.financingLable = "Financing";
exports.chainFreeLable = "*Chain Free ?";
exports.chainFreeOption1 = "Yes";
exports.chainFreeOption2 = "No";
exports.proofOfFinance = "*Proof of finance.";
exports.proofOfFinanceOption1 = "Certified Bank Statements";
exports.proofOfFinanceOption2 = "Mortgage in Principle Letter";
exports.proofOfFinanceOption3 = "Letter from Solicitor/Accountant";
exports.proofOfIdentity = "*Proof of Identity.";
exports.proofOfIdentityOption1 = "Passport";
exports.proofOfIdentityOption2 = "Full Driving License";
exports.proofOfIdentityOption3 = "National ID";
exports.proofOfAddress = "*Proof of Address.";
exports.proofOfAddressOption1 = "Bank Statement";
exports.proofOfAddressOption2 = "Utility Bill";
exports.heading = "Make an Offer";
exports.subHeading = "Enter your all information";
exports.submitText = "Submit";
exports.offerPriceError = "Please enter your offer price";
exports.radioFieldError = "Please select a field";
exports.proofOfFinanceError = "Please upload your proof of finance.";
exports.proofOfIdentityError = "Please upload your proof of identity.";
exports.proofOfAddressError = "Please upload your proof of Address.";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End