Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.apiFormContentType = "multipart/form-data";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";

exports.getTermsCondsApiEndPoint =
  "bx_block_terms_and_conditions/terms_and_conditions";
exports.getPrivacyPoliciesApiEndPoint =
  "bx_block_privacy_policies/privacy_policies";

exports.btnExampleTitle = "CLICK ME";
exports.termTab = "TERM";
exports.privacyTab = "PRIVACY";
// Customizable Area End